import { getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from 'components/layout'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

class HonorsCollegeWork extends React.Component {
  render() {
    const { data } = this.props
    const honorsCollege1 = getImage(data.honorsCollege1.childImageSharp)

    return (
      <Layout isHome={false}>
        <section
          className="content-section text-center"
          style={{
            paddingTop: '5%',
            backgroundColor: 'rgb(255, 255, 255, 0.15)',
          }}
        >
          <Container>
            <Row
              style={{
                border: 'solid #0099cc',
                margin: '10px',
                padding: '10px',
              }}
            >
              <h3 className="projectTitle">
                {' '}
                Ball State Honors College website{' '}
              </h3>

              <Row className="row-img">
                <GatsbyImage image={honorsCollege1} alt="photo of honors college website" style={{ marginLeft: '5%', marginRight: '5%' }} />
              </Row>
              <Row>
                <p>
                  During my time working with the Digital Corps, I had the
                  opportunity to develop a website for Ball State's Honors
                  College. This interactive and informational site is currently
                  being used to teach new students all about their degree
                  options, available advising resources, and an introduction to
                  Ball State's degree tracking software. Being the sole
                  developer on this project was a great experience, allowing me
                  to immerse myself in this project and collaborate with our
                  designers, UXers, and other important roles. This project
                  included a MySQL database, Rest API, and a React frontend. I
                  also developed a very helpful admin panel to assist the Honors
                  College staff with tracking user progress, form data, and
                  updating their four year plan resource for students to
                  download. The admin panel even includes functionality to
                  download the form and progress data to csv for use as a spread
                  sheet, which I utilized
                  <a href="https://github.com/PHPOffice/PHPExcel">
                    {' '}
                    PHPExcel{' '}
                  </a>{' '}
                  for.
                </p>
              </Row>
              <Row>
                <h4>Tools used:</h4>
                <p>
                  Javascript, JSON, PHP, Slim 3, React, MySQL, Babel, Html, Css,
                  Scss{' '}
                </p>
              </Row>
            </Row>
          </Container>
        </section>
      </Layout>
    );
  }
}

export default HonorsCollegeWork

export const query = graphql`
  query HonorsCollegeWorkQuery {
    honorsCollege1: file(name: { eq: "honorsCollege1" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
  }
`
